<div class="mat-elevation-z1">
  <div fxLayout="column" fxLayout.gt-sm="row">
    <mat-form-field fxFlex [ngStyle.gt-sm]="{ 'padding-right.px': 10, 'width.px': 10 }" fxFlexGap *ngIf="showFilter"
      class="filter">
      <input matInput [(ngModel)]="filterValue" (keyup)="filterKeyboardEvents.next($event)" placeholder="{{'workspaces.users_table.filter_placeholder' | translate }}">
    </mat-form-field>
  </div>
  <mat-table
    *ngIf="media.isActive('gt-xs') || media.isActive('gt-sm') || media.isActive('gt-md') || media.isActive('lg') || media.isActive('xl')"
    #table [dataSource]="dataSource">
    <ng-container cdkColumnDef="icon">
      <mat-header-cell fxFlex="60px" *cdkHeaderCellDef></mat-header-cell>
      <mat-cell fxFlex="60px" *cdkCellDef="let row">
        <mat-icon class="uilab-shady-icon" mat-list-icon>desktop_windows</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="username">
      <mat-header-cell *cdkHeaderCellDef>{{'workspaces.users_table.username' | translate }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a *ngIf="showLinks; else asText" [routerLink]="[row.id]">{{row.username}}</a>
        <ng-template #asText>
          <span>{{row.username}}</span>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="type">
      <mat-header-cell *cdkHeaderCellDef>{{'workspaces.users_table.compute_type' | translate }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.computeType}} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="status">
      <mat-header-cell *cdkHeaderCellDef>{{'workspaces.users_table.status' | translate }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{row.state}} </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="remove">
      <mat-header-cell fxFlex="90px" *cdkHeaderCellDef></mat-header-cell>
      <mat-cell fxFlex="90px" *cdkCellDef="let row">
        <uilab-icon-button class="delete" (click)="remove.emit(row)" matTooltip="Delete desktop" disabled = {{row.disabled}}>
          <mat-icon slot="icon">delete</mat-icon>
        </uilab-icon-button>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="['icon', 'username', 'type','status', 'remove']"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['icon', 'username', 'type', 'status','remove'];"></mat-row>
  </mat-table>
</div>
