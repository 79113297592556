export enum WorkspaceStatus {
    Unknown = 'UNKNOWN',
    NoWorkspace = 'NO_WORKSPACE',

    Available = 'AVAILABLE',
    Stopped = 'STOPPED',
    AdminMaintenance = 'ADMIN_MAINTENANCE',
    Updating = 'UPDATING',
    Terminating = 'TERMINATING',
    Stopping = 'STOPPING',
    Starting = 'STARTING',
    Rebuilding = 'REBUILDING',
    Rebooting = 'REBOOTING',
    Pending = 'PENDING',
    Maintenance = 'MAINTENANCE',
    Restoring = 'RESTORING',
    Terminated = 'TERMINATED',
    Unhealthy = 'UNHEALTHY',
    Impaired = 'IMPAIRED',
    Error = 'ERROR',
    Suspended = 'SUSPENDED'
}
