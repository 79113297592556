import { Component, OnInit } from "@angular/core";
import { WindowRefService } from "../window-ref.service";

@Component({
  selector: "app-end-session",
  templateUrl: "./end-session.component.html",
  styleUrls: ["./end-session.component.css"],
})
export class EndSessionComponent implements OnInit {
  constructor(private windowRef: WindowRefService) {}

  ngOnInit() {
    this.windowRef.window.close();
  }
}
