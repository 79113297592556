import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { HomeComponent } from "./home/home.component";
import { AccountResolverService } from "../account-resolver.service";
import { AdminComponent } from "./admin/admin.component";
import { UsersTableModule } from "./users-table/users-table.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { AdminGuard } from "../admin.guard";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { UilabBaseComponentsModule } from "@uilab/base-components-angular";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedModule } from "../shared/shared.module";
import { WorkspaceFeatureAccessGuard } from "./workspace-feature-access.guard";
import { POLL_INTERVAL_MS } from "./home/home.component";

@NgModule({
  declarations: [HomeComponent, AdminComponent, OnboardingComponent],
  imports: [
    CommonModule,
    ConnectCoreModule,
    UsersTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    UilabBaseComponentsModule,
    MatTooltipModule,
    SharedModule,
  ],
  providers: [
    AccountResolverService,
    AdminGuard,
    WorkspaceFeatureAccessGuard,
    {
      provide: POLL_INTERVAL_MS,
      useValue: 7000,
    },
  ],
})
export class WorkspacesModule {}
