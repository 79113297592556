import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import {
  ErrorHandlerService,
  ConnectApiClientService,
  Asset,
  AuthenticationService,
} from "@aveva/connect-web-core";

@Injectable({
  providedIn: "root",
})
export class AssetResolverService implements Resolve<any> {
  constructor(
    private authService: AuthenticationService,
    private errorHandler: ErrorHandlerService,
    private connectApiClient: ConnectApiClientService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string[]> {
    const user = await this.authService.getUser();
    try {
      const solution = await this.connectApiClient.solutionManagement.getSolution(user.solution);
      if (solution.contextId === solution.accountId) {
        return [];
      }

      return await this.getFullFolderPath(solution.contextId, solution.accountId);
    } catch (error) {
      this.errorHandler.handle(error, "accountSolutionNotAvailable");
      return [];
    }
  }

  async getAssetById(assetId: string): Promise<Asset> {
    try {
      return await this.connectApiClient.assets.getAsset(assetId);
    } catch (error) {
      this.errorHandler.handle(error, "assetDetailsNotAvailable");
    }
  }

  async getFullFolderPath(context: string, accountId: string): Promise<string[]> {
    const pathArray: string[] = [];
    let asset: Asset;
    let assetId = context;
    do {
      asset = await this.getAssetById(assetId);
      if (!asset) {
        break;
      }
      pathArray.push(asset.name);
      if (asset.parentId === accountId) {
        break;
      }
      assetId = asset.parentId;
    } while (true);
    return pathArray.reverse();
  }
}
