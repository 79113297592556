import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { HomeComponent } from "./home.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from "@angular/material/chips";
import { MatTooltipModule } from "@angular/material/tooltip";
import { POLL_INTERVAL_MS, AUTOSTART_AFTER_SECONDS } from "./home.component";
import { EndSessionComponent } from "./end-session.component";

@NgModule({
  declarations: [HomeComponent, EndSessionComponent],
  imports: [
    CommonModule,
    ConnectCoreModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: AUTOSTART_AFTER_SECONDS,
      useValue: 3,
    },
    {
      provide: POLL_INTERVAL_MS,
      useValue: 7000,
    },
  ],
})
export class AppStreamModule {}
