<con-page-layout>
  <con-page-layout-content>
    <div class="content-container">
      <div class="inner-container">
        <h3 class="loadInfo">{{ "appstream.end_session.thanks_title" | translate }}</h3>
        <p>
          {{ "appstream.end_session.session_is_finished" | translate }}
        </p>
      </div>
    </div>
  </con-page-layout-content>
</con-page-layout>
