import { AfterViewInit, Component, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { AppStreamService } from "../appstream/appstream.service";
import { WorkspaceService } from "../workspaces/workspace.service";

@Component({
  selector: "app-streaming",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements AfterViewInit {
  @ViewChild("anchor", { read: ViewContainerRef }) anchor: ViewContainerRef;
  streamingDisabled = false;
  streamingDisabledReason = "streaming.home.streaming_not_available";

  constructor(
    private router: Router,
    private appStreamService: AppStreamService,
    private workspaceService: WorkspaceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngAfterViewInit() {
    const appStreamFeatureEnabled = await this.appStreamService.featureEnabled().toPromise();
    let componentClass: Type<unknown> = null;
    if (appStreamFeatureEnabled) {
      const module = await import("../appstream/home.component");
      componentClass = module.HomeComponent;
    } else {
      const workspaceFeatureEnabled = await this.workspaceService.featureEnabled().toPromise();
      if (workspaceFeatureEnabled) {
        const module = await import("../workspaces/home/home.component");
        componentClass = module.HomeComponent;
      }
    }
    if (componentClass) {
      this.anchor.createComponent(componentClass);
    } else {
      this.streamingDisabled = true;
      const asErrorKey = await this.appStreamService.appStreamErrorKey?.toPromise();
      if (!asErrorKey) {
        const wsErrorKey = await this.workspaceService.workspacesErrorKey?.toPromise();
        if (wsErrorKey) {
          this.streamingDisabledReason = wsErrorKey;
        }
      } else {
        this.streamingDisabledReason = asErrorKey;
      }
    }
  }
}
