<con-page-layout>
  <con-page-header #pageHeader header="{{ 'batch_jobs.create_batch_job.page_title' | translate }}"> </con-page-header>
  <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
  <ng-template #iconTemplate>
    <mat-icon>chevron_right</mat-icon>
  </ng-template>

  <h3>{{ "batch_jobs.batch_job_form.batch_job_details_title" | translate }}</h3>

  <con-page-layout-content>
    <form #form="ngForm" [formGroup]="createJobForm">
      <mat-card>
        <mat-card-content>
          <mat-form-field [hideRequiredMarker]="true">
            <mat-label>{{ "batch_jobs.batch_job_form.batch_job_title" | translate }}</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>

          <mat-form-field apperance="fill">
            <mat-label>{{ "batch_jobs.batch_job_form.description_title" | translate }} (optional)</mat-label>
            <textarea rows="2" cols="50" matInput formControlName="description" cdkTextareaAutosize></textarea>
          </mat-form-field>

          <mat-form-field [hideRequiredMarker]="true">
            <mat-label>{{ "batch_jobs.batch_job_form.project_name_title" | translate }}</mat-label>
            <input matInput formControlName="projectName" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <h3>{{ "batch_jobs.batch_job_form.schedule_details_title" | translate }}</h3>
      <mat-card>
        <mat-card-content class="restrict-width">
          <cron-editor class="cron-editor-class" [(options)]="cronOptions" [(cron)]="schedule" formControlName="schedule"> </cron-editor>

          <div class="flex-display">
            <h3>{{ scheduleDescription | translate }}</h3>
            <mat-checkbox formControlName="enableSchedule" class="enableCheckBox">
              <strong>{{ "batch_jobs.manage_batch_jobs.enabled_text" | translate }}</strong>
            </mat-checkbox>
          </div>
        </mat-card-content>
      </mat-card>

      <h3>{{ "batch_jobs.batch_job_form.batch_job_parameters" | translate }}</h3>
      <mat-card>
        <mat-card-content>
          <div class="restrict-width">
            <mat-form-field appearance="fill" class="jobtype-dropdown" [hideRequiredMarker]="true">
              <mat-label class="mat-label-min-width">{{ "batch_jobs.batch_job_form.job_type_title" | translate }} </mat-label>
              <mat-select Class="panel-custom-width" formControlName="jobType">
                <mat-option *ngFor="let option of jobTypes" [value]="option">
                  {{ "batch_jobs.jobTypes." + option | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div id="parameter">
            <input type="file" class="file-input" accept=".pmlmac" (change)="onPmlFileSelected($event.target.files[0])" #pmlFileUpload />
            <div class="file-upload-input-field">
              <mat-form-field [hideRequiredMarker]="true">
                <div class="flex-display">
                  <input
                    readonly
                    matInput
                    formControlName="pmlFileName"
                    [value]="pmlFileName"
                    id="fileInput"
                    [required]="isPmlRequired ? true : null"
                    placeholder="PML macro file"
                  />
                  <mat-error *ngIf="!isPmlMacFile">{{ "batch_jobs.batch_job_form.pml_file_type_error" | translate }} </mat-error>
                  <ng-container *ngIf="pmlError; then pmlErrorState"></ng-container>
                  <ng-template #pmlErrorState>
                    <mat-icon class="mat-icon-color-red">{{ "batch_jobs.batch_job_form.browse_file_upload_failure_icon" | translate }}</mat-icon>
                  </ng-template>
                </div>
              </mat-form-field>
            </div>
            <button mat-stroked-button id="upload-buttons" (click)="pmlFileUpload.click()" type="button">
              {{ "batch_jobs.batch_job_form.browse_file_button_title" | translate }}
            </button>
          </div>
          <div id="parameter">
            <input
              type="file"
              class="file-input"
              accept=".mac"
              (change)="onCredentialFileSelected($event.target.files[0])"
              #credFileUpload
            />
            <div class="file-upload-input-field">
              <mat-form-field [hideRequiredMarker]="true">
                <div class="flex-display">
                  <input
                    readonly
                    matInput
                    formControlName="pmlCredentialsFileName"
                    [value]="pmlCredentialsFileName"
                    id="fileInput"
                    [required]="isCredRequired ? true : null"
                    placeholder="PML credentials file"
                  />
                  <mat-error *ngIf="!isMacFile">{{ "batch_jobs.batch_job_form.credential_file_type_error" | translate }} </mat-error>
                  <ng-container *ngIf="credError; then credErrorState"> </ng-container>
                  <ng-template #credErrorState>
                    <mat-icon class="mat-icon-color-red">{{ "batch_jobs.batch_job_form.browse_file_upload_failure_icon" | translate }}</mat-icon>
                  </ng-template>
                </div>
              </mat-form-field>
            </div>
            <button mat-stroked-button id="upload-buttons" (click)="credFileUpload.click()" type="button">
              {{ "batch_jobs.batch_job_form.browse_file_button_title" | translate }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="saveError; then errorMessage"></ng-container>
      <div id="button-segment">
        <button class="cancel" mat-raised-button (click)="onCancel()" type="button">
          {{ "cancel" | translate }}
        </button>
        <div>
          <button
            id="save-button"
            class="submit"
            mat-raised-button
            type="submit"
            (click)="onSubmit(createJobForm.value)"
            [disabled]="isButtonDisabled()"
            color="primary"
          >
            {{ "save" | translate }}
          </button>
        </div>
        <ng-template #errorMessage>
          <h4 class="h4-color-red">{{ saveError }}</h4>
        </ng-template>
      </div>
    </form>
  </con-page-layout-content>
</con-page-layout>
