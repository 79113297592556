import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  InjectionToken,
  Inject,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticatedUser } from "@aveva/connect-web-core";
import { WindowRefService } from "../../window-ref.service";
import { UserData, WorkspaceService } from "../workspace.service";
import CONSTANTS from "../../constants/constants";
import { BehaviorSubject, iif, NEVER, Observable, of, Subject } from "rxjs";
import { catchError, dematerialize, map, materialize, switchMap, takeUntil, mergeMap } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";
import polling, { IOptions } from "rx-polling";
import { SessionStorageService } from "src/app/session-storage.service";
import { Platform } from "@angular/cdk/platform";
import { WorkspaceStatus } from "../workspaces.constants";

export const POLL_INTERVAL_MS = new InjectionToken<number>("pollIntervalMs");

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  error: string;
  lastWorkspaceStatus: WorkspaceStatus;
  displayName: string;
  launching = false;
  onWindows = false;
  currentFolderPath: string[] = [];

  WorkspaceStatus = WorkspaceStatus;

  private pauser$ = new BehaviorSubject(false);
  private unsubscribe$: Subject<void> = new Subject<void>();
  private polledWorkspaceStatus$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private windowRef: WindowRefService,
    private sessionStorageService: SessionStorageService,
    private ref: ChangeDetectorRef,
    private platform: Platform,
    @Inject(POLL_INTERVAL_MS) private pollIntervalMs: number
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { user: AuthenticatedUser; arrayOfPaths: string[] }) => {
      this.displayName = this.getDisplayName(data.user);
      this.currentFolderPath = data.arrayOfPaths;
    });

    if (
      this.platform.SAFARI ||
      this.platform.ANDROID ||
      this.platform.IOS ||
      this.platform.WEBKIT
    ) {
      this.onWindows = false;
    } else {
      this.onWindows = true;
    }

    const pollingSubject$ = this.pauser$.pipe(
      switchMap((paused) => (paused ? NEVER : this.workspaceService.getUserWorkspaceStatus().pipe(materialize()))),
      dematerialize()
    );

    const pollingOptions: IOptions = {
      interval: this.pollIntervalMs,
      attempts: 1,
    };

    this.polledWorkspaceStatus$ = polling(pollingSubject$, pollingOptions).pipe(
      mergeMap((workspaceStatus: WorkspaceStatus) =>
        iif(
          () => workspaceStatus !== WorkspaceStatus.Stopped,
          of(workspaceStatus),
          this.workspaceService.startWorkspace().pipe(map(() => WorkspaceStatus.Starting))
        )),
      catchError((error: Error, caught: Observable<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.error = error.message;
        this.pauseWorkspaceStatusPolling();
        return caught;
      }),
      takeUntil(this.unsubscribe$)
    );

    this.polledWorkspaceStatus$.subscribe((workspaceStatus: WorkspaceStatus) => {
      this.lastWorkspaceStatus = workspaceStatus;

      if (
        !this.operationInProgress(workspaceStatus) &&
        workspaceStatus !== WorkspaceStatus.Unknown
      ) {
        this.pauseWorkspaceStatusPolling();
      }

      if (
        !this.sessionStorageService.noWorkspaceAutoLaunch &&
        workspaceStatus === WorkspaceStatus.Available
      ) {
        this.launchWorkspace();
        this.sessionStorageService.noWorkspaceAutoLaunch = true;
      }
    });
  }

  pauseWorkspaceStatusPolling() {
    if (!this.pauser$.value) {
      this.pauser$.next(true);
    }
  }

  restartWorkspaceStatusPolling() {
    if (this.pauser$.value) {
      this.pauser$.next(false);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sessionStorageService.noWorkspaceAutoLaunch = true;
  }

  operationInProgress(workspaceStatus: WorkspaceStatus): boolean {
    return [
      WorkspaceStatus.AdminMaintenance,
      WorkspaceStatus.Updating,
      WorkspaceStatus.Terminating,
      WorkspaceStatus.Stopping,
      WorkspaceStatus.Starting,
      WorkspaceStatus.Rebuilding,
      WorkspaceStatus.Rebooting,
      WorkspaceStatus.Pending,
      WorkspaceStatus.Maintenance,
      WorkspaceStatus.Restoring,
      WorkspaceStatus.Terminated,
    ].includes(workspaceStatus);
  }

  workspaceUnavailable(workspaceStatus: WorkspaceStatus): boolean {
    return [
      WorkspaceStatus.Unhealthy,
      WorkspaceStatus.Impaired,
      WorkspaceStatus.Error,
      WorkspaceStatus.Suspended,
    ].includes(workspaceStatus);
  }

  guideFinished() {
    this.lastWorkspaceStatus = WorkspaceStatus.Pending;
    this.workspaceService.createUserWorkspace().subscribe({
      next: () => this.restartWorkspaceStatusPolling(),
      error: (error: Error) => {
        this.error = error.message;
      },
    });
  }

  launchWorkspace() {
    this.launching = true;
    this.workspaceService.getWorkspaceLoginDetails().subscribe({
      next: (data: UserData) => {
        // eslint-disable-next-line max-len
        const customUrl = `avevaws:_-r_${data.directoryRegistrationCode}_-u_${data.userName}_-p_${data.userPassword}_--login-mode_0_--headless_--waiting-time_1_--quit-after-disconnect`;
        this.windowRef.window.location.assign(customUrl);
      },
      error: (error: Error) => {
        this.error = error.message;
      },
      complete: () => {
        setTimeout(() => (this.launching = false), 60000);
      },
    });
  }

  downloadMSI() {
    this.downloadFromS3(CONSTANTS.MSIKEY)
  }

  downloadCatalog() {
    this.downloadFromS3(CONSTANTS.SIGNEDCATALOGKEY)
  }

  downloadFromS3(KEY: string) {
    const document = this.windowRef.window.document;
    this.workspaceService.downloadFileByKey(KEY).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(new Blob(binaryData, { type: response.body.type }));
        downloadLink.href = url;
        const filename = this.getFileName(response, KEY);
        downloadLink.setAttribute("download", filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
          document.body.removeChild(downloadLink);
        }, 0);
      },
      error: (error: Error) => {
        this.error = error.message;
      },
    });
  }

  startOver() {
    this.error = undefined;
    this.lastWorkspaceStatus = undefined;
    this.restartWorkspaceStatusPolling();

    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  // TODO: confirm that the content-disposition header is here
  getFileName(response: HttpResponse<Blob>, defaultFileName: string): string {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get("content-disposition");
      const r = /(?:filename=")(.+)(?:")/;
      filename = r.exec(contentDisposition)[1];
    } catch (e) {
      filename = defaultFileName;
    }
    return filename;
  }

  getDisplayName(user: AuthenticatedUser): string {
    if (!user || !user.name) {
      return "";
    }

    if (typeof user.name !== "string") {
      return "";
    }

    let firstName = user.name.split(".")[0];
    if (!firstName) {
      return "";
    }

    if (firstName.includes("@")) {
      firstName = firstName.split("@")[0];
    }

    if (firstName.includes(".")) {
      firstName = firstName.split(".")[0].slice(0);
    }

    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }
}
