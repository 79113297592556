import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AuthenticatedUserResolverService,
  UserProfileMenuComponent,
} from "@aveva/connect-web-core";
import { CreateBatchJobComponent } from "./batch-jobs/create-batch-job/create-batch-job.component";
import { EditBatchJobComponent } from "./batch-jobs/edit-batch-job/edit-batch-job.component";
import { ManageBatchJobsComponent } from "./batch-jobs/manage-batch-jobs/manage-batch-jobs.component";
import { LaunchComponent } from "./launch/launch.component";
import { NotConnectAdminGuard } from "./not-connect-admin.guard";
import { AccountResolverService } from "./account-resolver.service";
import { AdminGuard } from "./admin.guard";
import { AdminComponent } from "./workspaces/admin/admin.component";
import { AssetResolverService } from "./asset-resolver.service";
import { HomeComponent as StreamingHomeComponent } from "./streaming/home.component";
import { ViewBatchJobComponent } from "./batch-jobs/view-batch-job/view-batch-job.component";
import { ListExecutionHistoryComponent } from "./batch-jobs/list-batch-job-execution-history/list-batch-job-execution-history.component";
import { ViewLogDetailsComponent } from "./batch-jobs/view-log-details/view-log-details.component";
import { RootComponent } from "./root/root.component";
import { BatchJobsFeatureAccessGuard } from "./batch-jobs/batch-jobs-feature-access.guard";
import { WorkspaceFeatureAccessGuard } from "./workspaces/workspace-feature-access.guard";
import { GetFileComponent } from "./filetransfer/getfile/getfile.component";
import { FileTransferFeatureAccessGuard } from "./filetransfer/filetransfer-feature-access.guard";
import { EndSessionComponent } from "./appstream/end-session.component";

type PathMatch = "full";

const appRoutes: Routes = [
  {
    path: "",
    component: LaunchComponent,
    pathMatch: "full" as PathMatch,
  },
];

const workspacesRoutes: Routes = [
  {
    path: "workspaces",
    component: RootComponent,
    canActivate: [NotConnectAdminGuard],
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },

    children: [
      {
        path: "",
        redirectTo: "/streaming/home",
        pathMatch: "full" as PathMatch,
      },
      {
        path: "admin",
        component: AdminComponent,
        resolve: {
          user: AuthenticatedUserResolverService,
          arrayOfPaths: AssetResolverService,
        },
        runGuardsAndResolvers: "always",
        canActivate: [WorkspaceFeatureAccessGuard, AdminGuard],
      },
    ],
  },
];

const appstreamRoutes: Routes = [
  {
    path: "appstream",
    component: RootComponent,
    canActivate: [NotConnectAdminGuard],
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },
    children: [
      {
        path: "",
        redirectTo: "/streaming/home",
        pathMatch: "full" as PathMatch,
      },
      {
        path: "end-session",
        component: EndSessionComponent,
      },
    ],
  },
];

const generalRoutes: Routes = [
  {
    path: "streaming",
    component: RootComponent,
    canActivate: [NotConnectAdminGuard],
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full" as PathMatch,
      },
      {
        path: "home",
        component: StreamingHomeComponent,
        resolve: {
          user: AuthenticatedUserResolverService,
          arrayOfPaths: AssetResolverService,
        },
      },
    ],
  },
  {
    path: "account",
    component: RootComponent,
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },
    children: [
      {
        path: "",
        redirectTo: "me",
        pathMatch: "full" as PathMatch,
      },
      {
        path: "me",
        component: UserProfileMenuComponent,
        data: {
          redirectRoute: ["streaming"],
        },
      },
    ],
  },
];

const batchjobsRoutes: Routes = [
  {
    path: "batchjobs",
    component: RootComponent,
    canActivate: [NotConnectAdminGuard],
    canActivateChild: [BatchJobsFeatureAccessGuard],
    data: { breadcrumb: { skip: true } },
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
      arrayOfPaths: AssetResolverService,
    },
    children: [
      {
        path: "managebatchjobs",
        data: { breadcrumb: { alias: "ManageBatchJobs" } },
        children: [
          {
            path: "",
            component: ManageBatchJobsComponent,
            resolve: {
              user: AuthenticatedUserResolverService,
              account: AccountResolverService,
              arrayOfPaths: AssetResolverService,
            },
          },
          {
            path: "createbatchjob",
            component: CreateBatchJobComponent,
            data: { breadcrumb: { alias: "CreateBatchJob" } },
          },
          {
            path: "editbatchjob/:id",
            component: EditBatchJobComponent,
            data: { breadcrumb: { alias: "EditBatchJob" } },
          },
          {
            path: "viewbatchjob/:id",
            children: [
              {
                path: "",
                component: ViewBatchJobComponent,
                data: { breadcrumb: { alias: "ViewBatchJob" } },
              },
              {
                path: "listexecutionhistory/:id/:executionTime",
                children: [
                  {
                    path: "",
                    component: ListExecutionHistoryComponent,
                    data: { breadcrumb: { alias: "ExecutionHistoryByJobAndTime" } },
                  },
                  {
                    path: "viewlogdetails/:id/:executionTime/:logFileName",
                    component: ViewLogDetailsComponent,
                    data: { breadcrumb: { alias: "LogDetails" } },
                  },
                ],
              },
              {
                path: "editbatchjob/:id",
                component: EditBatchJobComponent,
                data: { breadcrumb: { alias: "EditBatchJob" } },
              },
            ],
          },
          {
            path: "listexecutionhistory/:id",
            children: [
              {
                path: "",
                component: ListExecutionHistoryComponent,
                data: { breadcrumb: { alias: "ExecutionHistoryForBatchJob" } },
              },
              {
                path: "viewlogdetails/:id/:executionTime/:logFileName",
                component: ViewLogDetailsComponent,
                data: { breadcrumb: { alias: "LogDetails" } },
              },
            ],
          },
        ],
      },
    ],
  },
];

const filetransferRoutes: Routes = [
  {
    path: "filetransfer",
    component: RootComponent,
    canActivate: [NotConnectAdminGuard],
    canActivateChild: [FileTransferFeatureAccessGuard],
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
      arrayOfPaths: AssetResolverService,
    },
    children: [
      {
        path: "getfile",
        children: [
          {
            path: "",
            component: GetFileComponent,
            resolve: {
              user: AuthenticatedUserResolverService,
              account: AccountResolverService,
              arrayOfPaths: AssetResolverService,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
    RouterModule.forChild(workspacesRoutes),
    RouterModule.forChild(appstreamRoutes),
    RouterModule.forChild(batchjobsRoutes),
    RouterModule.forChild(generalRoutes),
    RouterModule.forChild(filetransferRoutes),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
