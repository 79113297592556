import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthenticationService } from "@aveva/connect-web-core";
import { AuthRoleCheckService } from "./auth-role-check-service";
@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private _authRoleCheckService: AuthRoleCheckService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot, //eslint-disable-line  @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot //eslint-disable-line  @typescript-eslint/no-unused-vars
  ): Promise<boolean | UrlTree> {
    const user = await this.authService.getUser();
    if (!user) {
      this.authService.navigateToSignIn();
      return false;
    }
    if (
      !(
        (await this._authRoleCheckService.isAccountAdmin(user)) ||
        (await this._authRoleCheckService.isAdminSuperUser(user))
      )
    ) {
      return false;
    }

    return true;
  }
}
