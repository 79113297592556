import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ManageBatchJobsComponent } from "../batch-jobs/manage-batch-jobs/manage-batch-jobs.component";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { EditBatchJobComponent } from "./edit-batch-job/edit-batch-job.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { CreateBatchJobComponent } from "./create-batch-job/create-batch-job.component";
import { AccountResolverService } from "../account-resolver.service";
import { AdminGuard } from "../admin.guard";
import { TranslateModule } from "@ngx-translate/core";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { ViewBatchJobComponent } from "./view-batch-job/view-batch-job.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ListExecutionHistoryComponent } from "./list-batch-job-execution-history/list-batch-job-execution-history.component";
import { ViewLogDetailsComponent } from "./view-log-details/view-log-details.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import {
  NgxMatMomentModule,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular-material-components/moment-adapter";
import { RouterModule } from "@angular/router";
import { CronEditorModule } from "ngx-cron-editor";
import { MatExpansionModule } from "@angular/material/expansion";
import { BreadcrumbModule } from "xng-breadcrumb";

@NgModule({
  declarations: [
    ManageBatchJobsComponent,
    EditBatchJobComponent,
    CreateBatchJobComponent,
    ViewBatchJobComponent,
    ListExecutionHistoryComponent,
    ViewLogDetailsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ConnectCoreModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    CronEditorModule,
    MatExpansionModule,
    BreadcrumbModule,
  ],
  providers: [
    AccountResolverService,
    AdminGuard,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ],
})
export class BatchJobsModule {}
