import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ManageBatchJobsService } from "../manage-batch-jobs.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-view-log-details",
  templateUrl: "./view-log-details.component.html",
  styleUrls: ["./view-log-details.component.scss"],
})
export class ViewLogDetailsComponent implements OnInit {
  public loaded: boolean;
  public error: string;
  public id: string;
  public content: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  public executionTime: string;
  public logFileName: string;
  public value: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  public formattedTime: string;
  constructor(
    public route: ActivatedRoute,
    private manageBatchJobService: ManageBatchJobsService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get("id");
    this.breadcrumbService.set(
      "@LogDetails",
      this.translate.instant(`batch_jobs.breadcrumbs.log_details_page`)
    );
    this.executionTime = this.route.snapshot.paramMap.get("executionTime");
    this.formattedTime = this.manageBatchJobService.convertDateToLocal(this.executionTime);
    this.logFileName = this.route.snapshot.paramMap.get("logFileName");
    this.manageBatchJobService
      .getLogDetails(this.id, this.executionTime, this.logFileName)
      .subscribe(
        (data) => {
          this.content = data;
          this.loaded = true;
        },
        (error: Error) => {
          this.error = error.message;
        }
      );
  }
}
