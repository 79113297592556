import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UsersTableComponent } from "./users-table.component";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CdkTableModule } from "@angular/cdk/table";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ConnectCoreModule,
    HttpClientModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    CdkTableModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    SharedModule,
  ],
  declarations: [UsersTableComponent],
  exports: [UsersTableComponent],
})
export class UsersTableModule {}
