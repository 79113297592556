import { Component } from "@angular/core";
import {
  PostLoginRedirectService,
  AuthenticationService,
  ErrorHandlerService,
  AuthenticatedUser,
} from "@aveva/connect-web-core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor(
    postLoginRedirect: PostLoginRedirectService,
    private authenticationService: AuthenticationService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang("en");
    this.translate.use("en");

    postLoginRedirect.watch();

    this.authenticationService.registerCallbackHandler(
      (user) => this.onAuthenticationCallbackSuccess(user),
      (error) => this.onAuthenticationCallbackError(error)
    );
  }

  async onAuthenticationCallbackError(error: Error) {
    return this.errorHandler
      .handle(error) //eslint-disable-line  @typescript-eslint/no-unused-vars
      .catch((_error) => { }) //eslint-disable-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      .then(() => this.authenticationService.signout());
  }

  async onAuthenticationCallbackSuccess(user: AuthenticatedUser) { //eslint-disable-line  @typescript-eslint/no-unused-vars

    if (await this.router.navigate(["/streaming/home"], { replaceUrl: true })) {
      return;
    }
    if (await this.router.navigate(["/workspaces/admin"], { replaceUrl: true })) {
      return;
    }
    if (await this.router.navigate(["/batchjobs/managebatchjobs"], { replaceUrl: true })) {
      return;
    }

    return this.errorHandler
      .handle(
        "callback.controller: Could not retrieve the user.account property",
        "accountDetailsNotAvailable"
      )
      .catch(() => { }) // eslint-disable-line @typescript-eslint/no-empty-function
      .then(() => this.authenticationService.signout());
  }
}
