import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { AuthenticationService } from "@aveva/connect-web-core";

@Injectable()
export class NotConnectAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) { }

  async canActivate(
    next: ActivatedRouteSnapshot, //eslint-disable-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot //eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<boolean | UrlTree> {
    const user = await this.authService.getUser();
    if (!user) {
      this.authService.navigateToSignIn();
      return false;
    }

    if (user.isSystemUser()) {
      return this.router.parseUrl("/notauthorized");
    }
    return true;
  }
}
