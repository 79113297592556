import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { WorkspaceService } from "./workspace.service";

@Injectable()
export class WorkspaceFeatureAccessGuard implements CanActivate {
  constructor(private workspaceService: WorkspaceService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> { //eslint-disable-line @typescript-eslint/no-unused-vars
    return await this.workspaceService.featureEnabled().toPromise();
  }
}
