import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthenticationService } from "@aveva/connect-web-core";
import { from, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { CONFIGURATION, Configuration } from "src/configuration";

// copypaste from Aveva Connect connect.web repo
const headerKeys = {
  authorization: "Authorization",
  contentType: "Content-Type",
};

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    @Inject(CONFIGURATION) private configuration: Configuration
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { //eslint-disable-line @typescript-eslint/no-explicit-any
    if (
      (!this.configuration.apis.workspaces ||
        !req.url.startsWith(this.configuration.apis.workspaces)) &&
      (!this.configuration.apis.batchjobs || !req.url.startsWith(this.configuration.apis.batchjobs)) &&
      (!this.configuration.apis.appstream || !req.url.startsWith(this.configuration.apis.appstream)) &&
      (!this.configuration.apis.filetransfer || !req.url.startsWith(this.configuration.apis.filetransfer))
    ) {
      return next.handle(req);
    }

    let headers = req.headers;
    if (!(req.body instanceof FormData) && !headers.has(headerKeys.contentType)) {
      headers = headers.set(headerKeys.contentType, "application/json");
    }
    if (req.body instanceof FormData && headers.has(headerKeys.contentType)) {
      headers = headers.delete(headerKeys.contentType);
    }
    if (!this.shouldAppendAuthorizationHeader(req)) {
      return this.finalize(req, headers, next);
    }

    return from(this.auth.getToken()).pipe(
      mergeMap((token) => {
        if (token) {
          headers = headers.set(headerKeys.authorization, `Bearer ${token}`);
        }
        return this.finalize(req, headers, next);
      })
    );
  }

  private shouldAppendAuthorizationHeader(req: HttpRequest<any>): boolean { //eslint-disable-line @typescript-eslint/no-explicit-any
    return !req.headers.has(headerKeys.authorization);
  }

  private finalize(
    req: HttpRequest<any>, //eslint-disable-line @typescript-eslint/no-explicit-any
    headers: HttpHeaders,
    next: HttpHandler
  ): Observable<HttpEvent<any>> { //eslint-disable-line @typescript-eslint/no-explicit-any
    req = req.clone({
      headers: headers,
    });
    return next.handle(req);
  }
}
