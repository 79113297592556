import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {
  ErrorHandlerService,
  AuthenticationService,
  ConnectApiClientService,
  Account,
} from "@aveva/connect-web-core";

@Injectable({
  providedIn: "root",
})
export class AccountResolverService implements Resolve<Account> {
  constructor(
    private errorHandler: ErrorHandlerService,
    private authService: AuthenticationService,
    private connectApiClient: ConnectApiClientService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Account> { //eslint-disable-line  @typescript-eslint/no-unused-vars
    try {
      const user = await this.authService.getUser();
      if (user.account === "ConnectAdministration") {
        return {
          id: user.account,
          name: "Connect Administration",
        } as Account;
      }
      return await this.connectApiClient.accountManagement.getAccount(user.account);
    } catch (error) {
      await this.errorHandler.handle(error, "accountsNotAvailable");
    }
  }
}
