import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent {
  @Output() readonly guideFinish: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly downloadMSI: EventEmitter<string> = new EventEmitter<string>();

  finished() {
    this.guideFinish.emit();
  }

  clickDownloadMSI() {
    this.downloadMSI.emit();
  }
}
