import { Inject, Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { CONFIGURATION, Configuration } from "src/configuration";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, map, shareReplay } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import cronparser from "cron-parser";

export interface IJobsData {
  createdAt: string;
  description: string;
  enableSchedule: boolean;
  id: string;
  isArchived: boolean;
  jobType: string;
  pmlCredentialsFileName: string;
  pmlFileName: string;
  projectName: string;
  schedule: string;
  title: string;
  pmlFileId: string;
  pmlCredentialsFileId: string;
  jobStatus: string;
  jobExecutions: IJobExecutions[];
}

export interface IJobExecutions {
  executionTime: string;
  status: JobStatus;
  executionEndTime: string;
}

export interface IListExecutionHistory {
  logFileName: string;
  executionTime: string;
  jobExecutionStatus: string;
}

const apiErrorCodes = {
  badAuthToken: 1003,
  unableToResolveInfrastructureContext: 1013,
  unableToProceedFurtherDueToMaintenance: 1014,
  batchJobFileUploadError: 2003,
  fileToBeUploadedNotFound: 2004,
  batchJobsFeatureNotAvailable: 2007,
};

const apiErrorsMap = Object.fromEntries(Object.entries(apiErrorCodes).map((a) => a.reverse()));

export const JobType = [
  "E3D-DESIGN",
  "E3D-DRAW",
  "E3D-ISODRAFT",
  "Administration-ADMIN",
  "Engineering-TAGS",
  "Engineering-CONFIGURATION",
];

export enum FileType {
  Macro = "MACRO",
  Credential = "CRED",
}

export enum JobStatus {
  Succeeded = 0,
  Failed = 1,
}

@Injectable({
  providedIn: "root",
})
export class ManageBatchJobsService {
  private featureEnabled$: Observable<boolean> | null;
  public offset = new Date().getTimezoneOffset();

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
    private translate: TranslateService
  ) {}

  get batchjobsBaseUrl(): Observable<string> {
    if (!this.configuration.apis.batchjobs) {
      return throwError(
        () => ({ error: apiErrorCodes.batchJobsFeatureNotAvailable } as HttpErrorResponse)
      );
    }
    return of(this.configuration.apis.batchjobs);
  }

  handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse> {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(() => `Error: ${error.error.message}`);
    } else {
      // Server-side errors
      if (error.status === 404) {
        return of(null);
      }
      const errorCode = parseInt(error.error, 10);
      const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
      return this.translate.get(`errors.api.${apiErrorTranslationKey}`).pipe(
        map((message: string) => {
          throw new Error(message);
        })
      );
    }
  }

  convertDateToLocal(executionTime: string) {
    const unformattedDate = new Date(executionTime);
    return unformattedDate.toLocaleDateString("en-US", {
      hour12: true,
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }

  convertCronToTimeZone(val: string, requiredtimezoneoffset: string) {
    if (val.startsWith("* * ")) {
      return val;
    }

    let minsAfterConvertion: any;

    const interval = cronparser.parseExpression(val);
    const fields = JSON.parse(JSON.stringify(interval.fields));
    const hours = fields.hour[0];
    const min = fields.minute[0];
    let totalMinutes;

    if (requiredtimezoneoffset === "utc") {
      totalMinutes = hours * 60 + min + this.offset;
    } else {
      totalMinutes = hours * 60 + min - this.offset; // Subtract offset to convert to local time
    }

    if (totalMinutes / 60 < 0) {
      totalMinutes += 24 * 60; // Add 24 hours in minutes
      fields.dayOfWeek = fields.dayOfWeek.map((day: number) => (day === 0 ? 6 : day - 1));
    } else if (totalMinutes / 60 >= 24) {
      totalMinutes -= 24 * 60; // Subtract 24 hours in minutes
      fields.dayOfWeek = fields.dayOfWeek.map((day: number) => (day + 1) % 7);
    }

    const hoursAfterConvertion = Math.floor(totalMinutes / 60) % 24;
    minsAfterConvertion = totalMinutes % 60;

    minsAfterConvertion = minsAfterConvertion < 0 ? minsAfterConvertion + 60 : minsAfterConvertion;

    // Update cron fields with UTC values
    fields.dayOfWeek = Array.from(new Set(fields.dayOfWeek));
    fields.minute = [minsAfterConvertion];
    fields.hour = [hoursAfterConvertion];

    const modifiedInterval = cronparser.fieldsToExpression(fields);
    const cronString = modifiedInterval.stringify();

    const cronExpressionParts = cronString.split(" ");
    return cronExpressionParts.join(" ");
  }

  featureEnabled(): Observable<boolean> {
    if (this.featureEnabled$) {
      return this.featureEnabled$;
    }

    this.featureEnabled$ = this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/featurestatus`, { observe: "response" })),
      catchError((error) => {
        const errorCode = parseInt(error.error, 10);
        if (
          [
            apiErrorCodes.batchJobsFeatureNotAvailable,
            apiErrorCodes.unableToResolveInfrastructureContext,
            apiErrorCodes.unableToProceedFurtherDueToMaintenance,
          ].includes(errorCode)
        ) {
          return of(false);
        }
        return throwError(() => error);
      }),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => response.status === 200), //eslint-disable-line @typescript-eslint/no-explicit-any
      shareReplay()
    );
    return this.featureEnabled$;
  }

  getBatchJobsList(): Observable<Array<IJobsData>> {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<IJobsData[]>(`${url}/batch`)),
      catchError(this.handleError.bind(this)),
      map((data: IJobsData[]) => {
        // Modify the property value of each object in the array
        data.forEach((item) => {
          const cronInUTC = item.schedule;
          const cronInLocal = this.convertCronToTimeZone(cronInUTC, "local");
          item.schedule = cronInLocal;
        });
        return data;
      })
    );
  }

  removeJobEntry(id: string) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.delete<IJobsData[]>(`${url}/batch/${id}`)),
      catchError(this.handleError.bind(this))
    );
  }

  getJobDetailsById(id: string): Observable<IJobsData> {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<IJobsData>(`${url}/batch/${id}`)),
      map((data: IJobsData) => {
        const cronInUTC = data.schedule;
        const cronInLocal = this.convertCronToTimeZone(cronInUTC, "local");
        data.schedule = cronInLocal;
        return data;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  batchJobFileUpload(fd: FormData, fileType: FileType) {
    const _headers = new HttpHeaders({
      enctype: "multipart/form-data",
      "X-FileType": fileType,
    });
    const options = { headers: _headers };
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.post(`${url}/batchfileupload`, fd, options)),
      catchError(this.handleError.bind(this))
    );
  }

  batchJobFileUploadPut(fd: FormData, fileType: FileType, id: string) {
    const _headers = new HttpHeaders({
      enctype: "multipart/form-data",
      "X-FileType": fileType,
    });
    const options = { headers: _headers };
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.put(`${url}/batchfileupload/${id}`, fd, options)),
      catchError(this.handleError.bind(this))
    );
  }

  editBatchJob(id: string, formData: FormData) {
    // call convertCronToTimeZone function to get value in local and set it to schedule property in formData
    if (formData["schedule"]) {
      const cronInLocal = formData["schedule"];
      const cronInUTC = this.convertCronToTimeZone(cronInLocal, "utc");
      formData["schedule"] = cronInUTC;
    }

    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.put(`${url}/batch/${id}`, formData)),
      catchError(this.handleError.bind(this))
    );
  }

  createBatchJob(formData: FormData) {
    // call convertCronToTimeZone function to get value in utc and set it to schedule property in formData
    if (formData["schedule"]) {
      const cronInLocal = formData["schedule"];
      const cronInUTC = this.convertCronToTimeZone(cronInLocal, "utc");
      formData["schedule"] = cronInUTC;
    }
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.post(`${url}/batch`, formData)),
      catchError(this.handleError.bind(this))
    );
  }

  getLogDetails(id: string, executionTime: string, logFileName: string) {
    const params = new HttpParams()
      .set("BatchId", id)
      .set("ExecutionTime", executionTime)
      .set("LogFileName", logFileName);
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get(`${url}/logs`, {
          params: params,
          responseType: "text" as "json",
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  getLogsByTimeRange(batchId: string, startTime?: string, endTime?: string) {
    let params;
    if (startTime && endTime) {
      params = new HttpParams()
        .set("BatchId", batchId)
        .set("StartTime", startTime)
        .set("EndTime", endTime);
    } else {
      params = new HttpParams().set("BatchId", batchId);
    }
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) =>
        this.httpClient.get(`${url}/logs/byTimeRange`, {
          params: params,
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  executeBatchJobNow(id: string) {
    return this.batchjobsBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/batch/executeNow/${id}`)),
      catchError(this.handleError.bind(this))
    );
  }
}
