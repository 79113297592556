import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { CONFIGURATION, Configuration } from "src/configuration";
import { Observable, of, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { catchError, concatMap, map, shareReplay } from "rxjs/operators";

export interface IFileData {
  url: string;
}

const apiErrorCodes = {
  unableToResolveInfrastructureContext: 1013,
  unableToProceedFurtherDueToMaintenance: 1014,
  filetransferFeatureNotAvailable: 2008,
};

const apiErrorsMap = Object.fromEntries(Object.entries(apiErrorCodes).map((a) => a.reverse()));

@Injectable({
  providedIn: "root",
})
export class FileTransferService {
  private featureEnabled$: Observable<boolean> | null;

  constructor(
    private httpClient: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration,
    private translate: TranslateService
  ) {}

  get fileTransferBaseUrl(): Observable<string> {
    if (!this.configuration.apis.filetransfer) {
      return throwError(
        () =>
          ({
            error: apiErrorCodes.filetransferFeatureNotAvailable,
          } as HttpErrorResponse)
      );
    }
    return of(this.configuration.apis.filetransfer);
  }

  handleError(error: HttpErrorResponse): Observable<never | HttpErrorResponse> {
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      return throwError(() => `Error: ${error.error.message}`);
    } else {
      // Server-side errors
      if (error.status === 404) {
        return of(null);
      }
      const errorCode = parseInt(error.error, 10);
      const apiErrorTranslationKey = apiErrorsMap[errorCode] ?? "default";
      return this.translate.get(`errors.api.${apiErrorTranslationKey}`).pipe(
        map((message: string) => {
          throw new Error(message);
        })
      );
    }
  }

  featureEnabled(): Observable<boolean> {
    if (this.featureEnabled$) {
      return this.featureEnabled$;
    }

    this.featureEnabled$ = this.fileTransferBaseUrl.pipe(
      concatMap((url) => this.httpClient.get(`${url}/featurestatus`, { observe: "response" })),
      catchError((error) => {
        const errorCode = parseInt(error.error, 10);
        if (
          [
            apiErrorCodes.filetransferFeatureNotAvailable,
            apiErrorCodes.unableToResolveInfrastructureContext,
            apiErrorCodes.unableToProceedFurtherDueToMaintenance,
          ].includes(errorCode)
        ) {
          return of(false);
        }
        return throwError(error);
      }),
      catchError(this.handleError.bind(this)),
      map((response: HttpResponse<any>) => response.status === 200), //eslint-disable-line @typescript-eslint/no-explicit-any
      shareReplay()
    );
    return this.featureEnabled$;
  }

  getFileByKey(fileKey: string): Observable<HttpResponse<IFileData>> {
    return this.fileTransferBaseUrl.pipe(
      concatMap((url) => this.httpClient.get<IFileData>(`${url}/GetFile?fileKey=${fileKey}`)),
      catchError(this.handleError.bind(this))
    );
  }

  getFile(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient
      .get<Blob>(url, {
        responseType: "blob" as "json",
        observe: "response",
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
}
