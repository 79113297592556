<con-page-layout>
  <div class="banner-text-container" *ngIf="onWindows === false">
    <mat-icon class="banner-icon" [inline]="false">information</mat-icon>
    <div class="banner-text">
      <span>{{ "workspaces.home.not_windows_warning.description" | translate }}</span>
    </div>
  </div>
  <con-page-header header="{{ 'workspaces.home.welcome_header' | translate }} {{ this.displayName }}">
    <ul class="breadcrumb" *ngIf="currentFolderPath.length > 0">
      <li>{{ "workspaces.current_folder_text" | translate }}</li>
      <li *ngFor="let path of currentFolderPath">
        <span>{{ path }}</span>
      </li>
    </ul>
  </con-page-header>

  <con-page-layout-content>
    <ng-container *ngIf="error; then errorState; else okState"></ng-container>

    <ng-template #errorState>
      <div class="content-container">
        <div class="inner-container">
          <div class="warning-container greeting-container">
            <h4 class="error-page-title">
              <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
              {{ "workspaces.home.warning_title" | translate }}
            </h4>
            <p>{{ error }}</p>
            <p [innerHtml]="'workspaces.home.warning_sub_title_html' | translate"></p>
            <uilab-button (click)="startOver()">{{ "workspaces.home.start_over_button" | translate }}</uilab-button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #okState>
      <ng-template [ngIf]="lastWorkspaceStatus === WorkspaceStatus.NoWorkspace" [ngIfElse]="afterGuide">
        <h4 class="lead">{{ "workspaces.home.setup_desktop_title" | translate }}</h4>
        <div class="content-container" [ngStyle]="{ padding: '0%' }">
          <div class="inner-container">
            <app-onboarding (downloadMSI)="downloadMSI()" (guideFinish)="guideFinished()"></app-onboarding>
          </div>
        </div>
      </ng-template>

      <ng-template #afterGuide>
        <ng-template [ngIf]="launching">
          <h4 class="lead">{{ "workspaces.home.start_desktop_title" | translate }}</h4>
          <div class="content-container">
            <div class="inner-container">
              <h3 class="loadInfo">{{ "workspaces.home.launching_title" | translate }}</h3>
              <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="!launching">
          <ng-container [ngSwitch]="lastWorkspaceStatus">
            <ng-container *ngSwitchDefault>
              <div class="content-container">
                <div class="inner-container">
                  <div class="warning-container greeting-container">
                    <h4 class="error-page-title">
                      <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
                      {{ "workspaces.home.warning_title" | translate }}
                    </h4>
                    {{ "workspaces.home.warning_status" | translate: { status: lastWorkspaceStatus } }}
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="!lastWorkspaceStatus ? lastWorkspaceStatus : 'not-match'">
              <div class="content-container">
                <div class="inner-container">
                  <h3 class="loadInfo">{{ "workspaces.home.waiting_title" | translate }}</h3>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="WorkspaceStatus.Unknown">
              <div class="content-container">
                <div class="inner-container">
                  <h3 class="loadInfo">{{ "workspaces.home.waiting_title" | translate }}</h3>
                  <p>
                    {{ "workspaces.home.waiting_user_sync_status" | translate }}
                  </p>
                  <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="
                operationInProgress(lastWorkspaceStatus) &&
                ![WorkspaceStatus.Starting, WorkspaceStatus.Pending].includes(lastWorkspaceStatus)
                  ? lastWorkspaceStatus
                  : 'not-match'
              ">
              <div class="content-container">
                <div class="inner-container">
                  <h3 class="loadInfo">{{ "workspaces.home.waiting_title" | translate }}</h3>
                  <p>
                    {{ "workspaces.home.waiting_status" | translate: { status: lastWorkspaceStatus } }}
                  </p>
                  <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="WorkspaceStatus.Stopped">
              <h4 class="lead">{{ "workspaces.home.start_desktop_title" | translate }}</h4>
              <div class="content-container">
                <div class="inner-container">
                  <p>
                    {{ "workspaces.home.stopped_status" | translate }}
                  </p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="WorkspaceStatus.Starting">
              <div class="content-container">
                <div class="inner-container">
                  <h3 class="loadInfo">{{ "workspaces.home.starting_title" | translate }}</h3>
                  <p>
                    {{ "workspaces.home.stopped_status" | translate }}
                  </p>
                  <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
                  <p>{{ "workspaces.home.starting_status" | translate }}</p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="WorkspaceStatus.Pending">
              <h4 class="lead">{{ "workspaces.home.setup_desktop_title" | translate }}</h4>
              <div class="content-container">
                <div class="inner-container">
                  <h3 class="loadInfo">{{ "workspaces.home.pending_title" | translate }}</h3>
                  <p>{{ "workspaces.home.pending_status" | translate }}</p>
                  <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="WorkspaceStatus.Available">
              <h4 class="lead">{{ "workspaces.home.start_desktop_title" | translate }}</h4>
              <div class="content-container">
                <div class="inner-container">
                  <div class="greeting-container">
                    <p>{{ "workspaces.home.greetings_text" | translate }}</p>
                  </div>
                  <uilab-button class="start-button" (click)="launchWorkspace()">{{
                    "workspaces.home.launch_button" | translate
                    }}</uilab-button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-template>

      <div *ngIf="lastWorkspaceStatus === WorkspaceStatus.Available" class="link-container">
        <p>{{ "workspaces.home.install_tools.title" | translate }}:</p>
        <div class="link-text-container">
          <p class="link-download">{{ "workspaces.home.install_tools.aws_ws_client" | translate }}</p>
          <a href="https://clients.amazonworkspaces.com/" target="_blank" rel="noopener noreferrer">
            <uilab-button class="button-link" appearance="outlined">{{ "workspaces.home.install_tools.install" |
              translate }}</uilab-button>
          </a>
        </div>
        <div class="link-text-container">
          <p class="link-download">{{ "workspaces.home.install_tools.aveva_client_app" | translate }}</p>
          <a (click)="downloadMSI()" download>
            <uilab-button class="button-link" appearance="outlined">{{
              "workspaces.home.install_tools.download" | translate
              }}</uilab-button>
          </a>
        </div>
        <div class="link-text-container">
          <p class="link-download">{{ "workspaces.home.install_tools.aveva_client_app_signed_catalog" | translate }}</p>
          <a (click)="downloadCatalog()" download>
            <uilab-button class="button-link" appearance="outlined">{{
              "workspaces.home.install_tools.download" | translate
              }}</uilab-button>
          </a>
        </div>
      </div>
    </ng-template>
  </con-page-layout-content>
</con-page-layout>