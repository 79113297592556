import { InjectionToken } from "@angular/core";

export const CONFIGURATION = new InjectionToken<Configuration>(
  "workspaces-capability-configuration"
);

export interface Configuration {
  clientId: string;
  authority: string;
  connect: string;
  singleSignOutUrl: string;
  userProfileManagementUrl: string;
  apis: {
    am: string;
    platform: string;
    registry: string;
    uam: string;
    scm: string;
    logging: string;
    notifications: string;
    assets: string;
    workspaces: string;
    appstream: string;
    batchjobs: string;
    filetransfer: string;
  };
}
