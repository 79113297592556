<con-page-layout>
  <div>
    <div class="flex-display">
      <con-page-header header="{{ 'batch_jobs.view_log_details.page_title' | translate }}"> </con-page-header>
    </div>
    <xng-breadcrumb [separator]="iconTemplate" class="breadcrumb"></xng-breadcrumb>
    <ng-template #iconTemplate>
      <mat-icon>chevron_right</mat-icon>
    </ng-template>
    <con-page-layout-content>
      <div class="flex-container center" *ngIf="!loaded">
        <mat-spinner class="spinner-align" color="primary"></mat-spinner>
      </div>
      <div *ngIf="loaded">
        <mat-card class="reduce-header-size">
          <mat-card-content>
            <mat-label>
              <div class="align-labels">
                <mat-label class="bold-label">{{ "batch_jobs.view_log_details.log_file_name" | translate }}:</mat-label>
                <p>{{ logFileName }}</p>
                <mat-label class="bold-label"
                  >{{ "batch_jobs.view_log_details.execution_schedule" | translate }}</mat-label
                >
                <p>{{ formattedTime }}</p>
              </div>
            </mat-label>
          </mat-card-content>
        </mat-card>

        <mat-card class="margin-top">
          <mat-card-content class="multiline">
            {{ content }}
          </mat-card-content>
        </mat-card>
      </div>
    </con-page-layout-content>
  </div>
</con-page-layout>
