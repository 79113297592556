import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { AuthenticatedUser } from "@aveva/connect-web-core";
import { ConfirmationPopupService } from "src/app/shared/services/confirmation-popup.service";
import cronstrue from "cronstrue";
import {
  IJobExecutions,
  IJobsData,
  JobStatus,
  ManageBatchJobsService,
} from "../manage-batch-jobs.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-view-batch-job",
  templateUrl: "./view-batch-job.component.html",
  styleUrls: ["./view-batch-job.component.scss"],
})
export class ViewBatchJobComponent implements OnInit {
  pmlFileName: string;
  credFileName: string;
  public currentUser: AuthenticatedUser;
  public batchJobDetails: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  public jobData: IJobsDataView;
  public loaded = false;
  public error: string;
  JobStatus = JobStatus;
  displayedColumns = ["dateTime", "status"];
  executionTime: string;
  jobTitle: string;
  dataSource = new MatTableDataSource<any>([]); //eslint-disable-line @typescript-eslint/no-explicit-any
  private paginator: MatPaginator;
  constructor(
    private route: ActivatedRoute,
    private confirmationDialog: ConfirmationPopupService,
    private manageBatchJobService: ManageBatchJobsService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {}

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setPaginatorAttributes();
  }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.breadcrumbService.set(
      "@ViewBatchJob",
      this.translate.instant(`batch_jobs.breadcrumbs.view_page`)
    );

    this.manageBatchJobService.getJobDetailsById(id).subscribe(
      (data) => {
        this.jobData = data as IJobsDataView;
        this.jobData.createdAt = new Date(this.jobData.createdAt).toDateString();

        try {
          // manipulating the schedule description to reflect local time.
          this.jobData.humanReadableSchedule = cronstrue.toString(this.jobData.schedule);
        } catch (e) {
          this.jobData.humanReadableSchedule = "Invalid Cron expression";
        }

        // formatting execution time shown in Execution history
        if (data.jobExecutions) {
          data.jobExecutions.reverse();
          this.dataSource = new MatTableDataSource<IJobsDataView>(
            data.jobExecutions.map((x) => {
              const view = x as IJobsDataView;
              let timeToShow;

              if ("executionEndTime" in view && view.executionEndTime !== null) {
                timeToShow = view.executionEndTime;
              } else {
                timeToShow = view.executionTime;
              }
              view.formattedDate = this.manageBatchJobService.convertDateToLocal(timeToShow);
              return view;
            })
          );
        }
        this.loaded = true;
      },
      (error: Error) => {
        this.error = error.message;
      }
    );
  }

  setPaginatorAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  executeNow(id: string, title: string) {
    this.manageBatchJobService.executeBatchJobNow(id).subscribe(() => {
      this.confirmationDialog.confirmDialog({
        title: "batch_jobs.manage_batch_jobs.execute_now_batch_job_title",
        message: this.translate.instant(
          "batch_jobs.manage_batch_jobs.execute_now_batch_job_description",
          { title }
        ),
        confirmCaption: "confirmation_popup.ok",
        cancelCaption: "",
      });
    });
  }
}

interface IJobsDataView extends IJobsData, IJobExecutions {
  humanReadableSchedule: string;
  formattedDate: string;
}
