<ng-container #anchor>
  <con-page-layout *ngIf="streamingDisabled">
    <con-page-layout-content>
      <div class="content-container">
        <div class="inner-container">
          <div class="warning-container greeting-container">
            <h4 class="error-page-title">
              <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
              {{ "streaming.home.warning_title" | translate }}
            </h4>
            <p>{{ streamingDisabledReason | translate }}</p>
          </div>
        </div>
      </div>
    </con-page-layout-content>
  </con-page-layout>
</ng-container>
