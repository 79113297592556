import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthRoleCheckService } from "../auth-role-check-service";
import {
  Account,
  AuthenticatedUser,
  SideNavLink,
} from "@aveva/connect-web-core";
import { TranslateService } from "@ngx-translate/core";
import { ManageBatchJobsService } from "../batch-jobs/manage-batch-jobs.service";
import { CONFIGURATION, Configuration } from "src/configuration";
import { HttpClient } from "@angular/common/http";
import { WorkspaceService, WorkspaceUser } from "../workspaces/workspace.service";
import { AppStreamService } from "../appstream/appstream.service";
import { FileTransferService } from "../filetransfer/filetransfer.service";

@Component({
  selector: "app-root",
  templateUrl: "./root.component.html",
  styleUrls: ["./root.component.scss"],
})
export class RootComponent implements OnInit {
  user: AuthenticatedUser;
  account?: Account;
  accountIcon: string;
  links: SideNavLink[] = [];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private manageBatchJobsService: ManageBatchJobsService,
    private workspaceService: WorkspaceService,
    private appStreamService: AppStreamService,
    private fileTransferService: FileTransferService,
    private authRoleCheckService: AuthRoleCheckService,
    private http: HttpClient,
    @Inject(CONFIGURATION) private configuration: Configuration
  ) { }

  ngOnInit() {
    this.route.data.subscribe(async (data: { user: AuthenticatedUser; account: Account }) => {
      this.user = data.user;
      this.account = data.account;
      await this.getIcon(data.account);
      await this.createLinks();
    });
  }

  private async createLinks(): Promise<void> {
    this.links = [
      {
        label: this.translate.instant("home"),
        iconLigature: "home",
        routerLink: ["/streaming/home"],
      },
    ];
    if (
      // Here we give either Account Admin or a user with Admin Superuser role to access both Manage Desktops and Batch Jobs
      // Batch Jobs is a separate feature, so if these conditions change, these should be changed in /batch-jobs/batch-jobs-feature-access.guard.ts as well
      (await this.authRoleCheckService.isAccountAdmin(this.user))
      || (await this.authRoleCheckService.isAdminSuperUser(this.user))
    ) {
      const appStreamFeatureEnabled = await this.appStreamService.featureEnabled().toPromise();
      let workspaceUsers: WorkspaceUser[] = [];
      const workspaceFeatureEnabled = await this.workspaceService.featureEnabled().toPromise();
      if (workspaceFeatureEnabled) {
        workspaceUsers = [...(await this.workspaceService.getWorkspaces().toPromise())];
      }
      if (
        (appStreamFeatureEnabled && workspaceFeatureEnabled && workspaceUsers.length > 0) ||
        (workspaceFeatureEnabled && !appStreamFeatureEnabled)
      ) {
        this.links.push({
          label: this.translate.instant("workspaces.admin.page_title"),
          iconLigature: "desktop_windows",
          routerLink: ["/workspaces/admin"],
        });
      }
      this.manageBatchJobsService.featureEnabled().subscribe((featureEnabled) => {
        if (featureEnabled) {
          this.links.push({
            label: this.translate.instant("batch_jobs.manage_batch_jobs.page_title"),
            iconLigature: "event",
            routerLink: ["/batchjobs/managebatchjobs"],
          });
        }
      });
      this.fileTransferService.featureEnabled().subscribe((featureEnabled) => {
        if (featureEnabled) {
          this.links.push({
            label: this.translate.instant("filetransfer.page_title"),
            iconLigature: "cloud_sync",
            routerLink: ["/filetransfer/getfile"],
          });
        }
      });
    }
  }

  private async getIcon(account: Account): Promise<void> {
    try {
      const url = `${this.configuration.apis.am}/accounts/${account.id}/icon`;
      const icon = await this.http
        .get(url, {
          responseType: "text",
        })
        .toPromise();
      if (icon) {
        this.accountIcon = `data:image/png;base64,${icon}`;
      }
    } catch (error) {
      console.error("Error", error);
    }
  }
}
