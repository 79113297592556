<con-page-layout>
  <con-page-header header="{{ 'appstream.home.welcome_header' | translate }} {{ this.displayName }}">
    <ul class="breadcrumb" *ngIf="currentFolderPath.length > 0">
      <li>{{ "workspaces.current_folder_text" | translate }}</li>
      <li *ngFor="let path of currentFolderPath">
        <span>{{ path }}</span>
      </li>
    </ul>
  </con-page-header>

  <con-page-layout-content>
    <ng-container *ngIf="error; then errorState; else okState"></ng-container>

    <ng-template #errorState>
      <div class="content-container">
        <div class="inner-container">
          <div class="warning-container greeting-container">
            <h4 class="error-page-title">
              <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
              {{ "appstream.home.warning_title" | translate }}
            </h4>
            <p>{{ error }}</p>
            <button mat-raised-button (click)="startOver()">
              {{ "appstream.home.start_over_button" | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #okState>
      <ng-container
        *ngIf="(lastUserStatus && !validUserStatuses.includes(lastUserStatus)) || (!advancedMode && autoStartFleetStackFound === false)"
      >
        <div class="content-container">
          <div class="inner-container">
            <div class="warning-container greeting-container">
              <h4 class="error-page-title">
                <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
                {{ "appstream.home.warning_title" | translate }}
              </h4>
              <p>{{ "appstream.home.warning_status" | translate }}</p>
              <button mat-raised-button (click)="startOver()">
                {{ "appstream.home.start_over_button" | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!lastUserStatus || !appStreamFleetsStacks">
        <div class="content-container">
          <div class="inner-container">
            <h3 class="loadInfo">{{ "appstream.home.waiting_title" | translate }}</h3>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="lastUserStatus === UserStatus.Waiting">
        <div class="content-container">
          <div class="inner-container">
            <h3 class="loadInfo">{{ "appstream.home.waiting_title" | translate }}</h3>
            <p>
              {{ "appstream.home.waiting_user_sync_status" | translate }}
            </p>
            <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="lastUserStatus === UserStatus.Ready">
        <ng-container *ngIf="advancedMode">
          <div class="form-group">
            <mat-card *ngFor="let item of appStreamFleetsStacks">
              <mat-chip-list class="fleet-status">
                <mat-chip selected color="{{ item.state === 'RUNNING' ? 'primary' : 'warn' }}" [matTooltip]="item.state">{{
                  item.availableSeats
                }}</mat-chip>
              </mat-chip-list>
              <mat-card-title> {{ "appstream.home.fleet_stack_card_title" | translate }} </mat-card-title>
              <mat-card-content>
                <p>
                  {{ "appstream.home.fleet_stack_card_name_label" | translate }}: {{ item.name }}<br />
                  {{ "appstream.home.fleet_stack_card_fleet_name_label" | translate }}: {{ item.fleetName }}<br />
                  {{ "appstream.home.fleet_stack_card_stack_name_label" | translate }}: {{ item.stackName }}<br />
                  {{ "appstream.home.fleet_stack_card_instance_type_label" | translate }}: {{ item.instanceType }}
                </p>
              </mat-card-content>
              <mat-card-actions>
                <button mat-raised-button color="primary" (click)="startAppStream(item.id)" [disabled]="item.state !== 'RUNNING'">
                  {{ "appstream.home.start_button" | translate }}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-container>

        <ng-container *ngIf="!advancedMode">
          <ng-template [ngIf]="autoStartInProgress">
            <h4 class="lead">{{ "appstream.home.start_desktop_title" | translate }}</h4>
            <div class="content-container">
              <div class="inner-container">
                <h3 class="loadInfo">{{ "appstream.home.launching_title" | translate }}</h3>
                <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
                <uilab-button class="button-link" appearance="outlined" (click)="cancelAutoStart()">{{
                  "appstream.home.cancel_autostart_button" | translate
                }}</uilab-button>
              </div>
            </div>
          </ng-template>

          <ng-template [ngIf]="!autoStartInProgress && autoStartFleetStackFound">
            <h4 class="lead">{{ "appstream.home.start_desktop_title" | translate }}</h4>
            <div class="content-container">
              <div class="inner-container">
                <div class="greeting-container">
                  <p>{{ "appstream.home.greetings_text" | translate }}</p>
                </div>
                <uilab-button class="start-button" (click)="startSession()">{{ "appstream.home.start_button" | translate }}</uilab-button>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-template>
  </con-page-layout-content>
</con-page-layout>
