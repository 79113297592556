import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  get tenantId(): string {
    return sessionStorage.getItem('tenantId');
  }

  set tenantId(value: string) {
    if (value) {
      sessionStorage.setItem('tenantId', value);
    } else {
      sessionStorage.removeItem('tenantId');
    }
  }

  get noWorkspaceAutoLaunch(): boolean {
    return sessionStorage.getItem('noWorkspaceAutoLaunch') === 'true';
  }

  set noWorkspaceAutoLaunch(value: boolean) {
    sessionStorage.setItem('noWorkspaceAutoLaunch', value.toString());
  }
}
