import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { TranslateModule } from "@ngx-translate/core";
import { UilabBaseComponentsModule } from "@uilab/base-components-angular";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { GetFileComponent } from "./getfile/getfile.component";

@NgModule({
  declarations: [
    GetFileComponent,
  ],
  imports: [
    CommonModule,
    ConnectCoreModule,
    TranslateModule,
    UilabBaseComponentsModule,
    MatCardModule,
    MatIconModule,
  ]
})
export class FileTransferModule {}
