import { Component, OnInit } from "@angular/core";
import { WindowRefService } from "../../window-ref.service";
import { FileTransferService, IFileData } from "../filetransfer.service";
import CONSTANTS from "../../constants/constants";
import { HttpResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-getfile",
  templateUrl: "./getfile.component.html",
  styleUrls: ["./getfile.component.scss"],
})
export class GetFileComponent implements OnInit {
  error: string;
  currentFolderPath: string[] = [];
  featureEnabled: boolean;

  constructor(
    public route: ActivatedRoute,
    private fileTransferService: FileTransferService,
    private windowRef: WindowRefService
  ) {}

  async ngOnInit() {
    this.route.data.subscribe((data: { arrayOfPaths: string[] }) => {
      this.currentFolderPath = data.arrayOfPaths;
    });

    this.fileTransferService.featureEnabled().subscribe(
      (featureEnabled) => (this.featureEnabled = featureEnabled),
      (error) => (this.error = error.message)
    );
  }

  downloadCloudStorageClient() {
    const document = this.windowRef.window.document;
    this.fileTransferService
      .getFileByKey(CONSTANTS.FILEKEY_CLOUDSTORAGE)
      .pipe(
        switchMap((fileData: HttpResponse<IFileData>) => {
          const url = fileData.url;
          return this.fileTransferService.getFile(url);
        })
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement("a");
          const url = URL.createObjectURL(new Blob(binaryData, { type: response.body.type }));
          downloadLink.href = url;
          const filename = this.getFileName(response, CONSTANTS.FILEKEY_CLOUDSTORAGE);
          downloadLink.setAttribute("download", filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            document.body.removeChild(downloadLink);
          }, 0);
        },
        (error: Error) => {
          this.error = error.message;
        }
      );
  }

  getFileName(response: HttpResponse<Blob>, defaultFileName: string): string {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get("content-disposition");
      const r = /(?:filename=")(.+)(?:")/;
      filename = r.exec(contentDisposition)[1];
    } catch (e) {
      filename = defaultFileName;
    }
    return filename;
  }
}
