<mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" class="stepper">
  <mat-step label="{{'workspaces.home.onboarding.step1.stepper_title' | translate }}">
    <div class="info-container">
      <div class="information">
        <h4 class="label">{{'workspaces.home.onboarding.step1.title' | translate }}</h4>
        {{'workspaces.home.onboarding.step1.text' | translate }}
      </div>
      <a href="https://clients.amazonworkspaces.com/" target="_blank" rel="noopener noreferrer">
        <uilab-button class="button-link">{{'workspaces.home.onboarding.step1.download_button' | translate }}</uilab-button>
      </a>
    </div>
    <button class="unstyle-button stepper-back" type="button" matStepperPrevious>
      <uilab-button appearance="outlined" disabled>{{'workspaces.home.onboarding.back_button' | translate }}</uilab-button>
    </button>
    <button class="unstyle-button stepper-next" type="button" matStepperNext>
      <uilab-button>{{'workspaces.home.onboarding.next_button' | translate }}</uilab-button>
    </button>
</mat-step>

  <mat-step label="{{'workspaces.home.onboarding.step2.stepper_title' | translate }}">
    <div class="info-container">
      <div class="information">
        <h4 class="label">{{'workspaces.home.onboarding.step2.title' | translate }}</h4>
        {{'workspaces.home.onboarding.step2.text1' | translate }}
        <br>
        {{'workspaces.home.onboarding.step2.text2' | translate }}
      </div>
      <uilab-button (click)="clickDownloadMSI()">{{'workspaces.home.onboarding.step2.download_button' | translate }}</uilab-button>
    </div>
    <button class="unstyle-button stepper-back" type="button" matStepperPrevious>
      <uilab-button appearance="outlined">{{'workspaces.home.onboarding.back_button' | translate }}</uilab-button>
    </button>
    <button class="unstyle-button stepper-next" type="button" matStepperNext>
      <uilab-button>{{'workspaces.home.onboarding.next_button' | translate }}</uilab-button>
    </button>
  </mat-step>

  <mat-step label="{{'workspaces.home.onboarding.step3.stepper_title' | translate }}">
    <div class="info-container">
      <div class="information">
        <h4 class="label">{{'workspaces.home.onboarding.step3.title' | translate }}</h4>
        {{'workspaces.home.onboarding.step3.text1' | translate }}
        <br />
        <br />
        {{'workspaces.home.onboarding.step3.text2' | translate }}
      </div>
    </div>
    <button class="unstyle-button stepper-back" type="button" matStepperPrevious>
      <uilab-button appearance="outlined" >{{'workspaces.home.onboarding.back_button' | translate }}</uilab-button>
    </button>
    <button class="unstyle-button stepper-next" type="button" matStepperNext>
      <uilab-button (click)="finished()">{{'workspaces.home.onboarding.finish_button' | translate }}</uilab-button>
    </button>
  </mat-step>
</mat-horizontal-stepper>