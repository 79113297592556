import {
  Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, OnDestroy
} from '@angular/core';
import { AuthenticatedUser } from '@aveva/connect-web-core';
import { MatTableDataSource } from '@angular/material/table';
import { MediaObserver } from '@angular/flex-layout';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { of, Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UsersTableComponent implements OnInit, OnDestroy {
  @Input()
  get currentUser(): AuthenticatedUser { return this._currentUser; }
  set currentUser(value: AuthenticatedUser) {
    this._currentUser = value;
  }
  _currentUser: AuthenticatedUser;

  @Input()
  get group(): string { return this._group; }
  set group(value: string) {
    this._group = value;
  }
  _group: string;

  @Input()
  get isSystemGroup(): boolean { return this._isSystemGroup; }
  set isSystemGroup(value: boolean) {
    this._isSystemGroup = value;
  }
  _isSystemGroup = true;

  @Input()
  get groupExternalId(): string { return this._groupExternalId; }
  set groupExternalId(value: string) {
    this._groupExternalId = value;
  }
  _groupExternalId: string;

  @Input()
  get nextToken(): string { return this._nextToken; }
  set nextToken(value: string) {
    this._nextToken = value;
  }
  _nextToken: string;

  @Input()
  get showLinks(): boolean { return this._showLinks; }
  set showLinks(value: boolean) {
    this._showLinks = coerceBooleanProperty(value);
  }
  _showLinks = true;

  @Input()
  get showFilter(): boolean { return this._showFilter; }
  set showFilter(value: boolean) {
    this._showFilter = coerceBooleanProperty(value);
  }
  _showFilter = true;

  @Input()
  get showPaging(): boolean { return this._showPaging; }
  set showPaging(value: boolean) {
    this._showPaging = coerceBooleanProperty(value);
  }
  _showPaging = true;

  @Input()
  get showAddButton(): boolean { return this._showAddButton; }
  set showAddButton(value: boolean) {
    this._showAddButton = coerceBooleanProperty(value);
  }
  _showAddButton = true;

  @Input()
  get removeIcon(): string { return this._removeIcon; }
  set removeIcon(value: string) {
    this._removeIcon = value;
  }
  _removeIcon = 'delete';

  @Output() usersChange = new EventEmitter();
  @Input()
  get users(): any { return this._users; } //eslint-disable-line @typescript-eslint/no-explicit-any
  set users(value: any) { //eslint-disable-line @typescript-eslint/no-explicit-any
    this._users = value;
    this.dataSource = new MatTableDataSource<any>(value); //eslint-disable-line @typescript-eslint/no-explicit-any
    this.usersChange.emit(value);
  }
  _users: any[] = []; //eslint-disable-line @typescript-eslint/no-explicit-any
  _config: any; //eslint-disable-line @typescript-eslint/no-explicit-any

  @Output() readonly remove: EventEmitter<any> = new EventEmitter<any>(); //eslint-disable-line @typescript-eslint/no-explicit-any
  @Output() readonly filter: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly paginate: EventEmitter<number> = new EventEmitter<number>();


  dataSource = new MatTableDataSource<any>([]); //eslint-disable-line @typescript-eslint/no-explicit-any
  filterValue = '';
  pageSize = 100;
  filterKeyboardEvents = new Subject<KeyboardEvent>();

  constructor(
    public media: MediaObserver
  ) { }

  ngOnInit() {
    this.filterKeyboardEvents
      .pipe(
        map(event => (event.target as any).value.trim()), //eslint-disable-line @typescript-eslint/no-explicit-any
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap(search => of(search))
      )
      .subscribe(() => this._filter());
  }

  ngOnDestroy(): void {
    if (this.filterKeyboardEvents) {
      this.filterKeyboardEvents.unsubscribe();
    }
  }

  _filter() {
    if (this.filter.observers.length > 0) {
      this.filter.emit(this.filterValue);
    }
  }
}
