import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { IListExecutionHistory, ManageBatchJobsService } from "../manage-batch-jobs.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-list-batch-job-execution-history",
  templateUrl: "./list-batch-job-execution-history.component.html",
  styleUrls: ["./list-batch-job-execution-history.component.scss"],
})
export class ListExecutionHistoryComponent implements OnInit {
  dataSource = new MatTableDataSource<IListExecutionHistoryView>([]);
  displayedColumns = ["lastRunName", "logName"];
  public loaded = false;
  public error: string;
  public id: string;
  datePickerForm: UntypedFormGroup;
  public executionTime: string;
  jobTitle: string;
  titleFetched = false;
  filterValid = true;
  maxDate: Date;
  color: ThemePalette = "primary";

  constructor(
    public route: ActivatedRoute,
    private manageBatchJobService: ManageBatchJobsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    const transaltedStr = this.translate.instant(`batch_jobs.breadcrumbs.execution_history_page`);
    this.breadcrumbService.set("@ExecutionHistoryForBatchJob", transaltedStr);
    this.breadcrumbService.set("@ExecutionHistoryByJobAndTime", transaltedStr);
    this.id = this.route.snapshot.paramMap.get("id");
    this.executionTime = this.route.snapshot.paramMap.get("executionTime");
    const date = new Date();
    this.maxDate = new Date(date.setSeconds(date.getSeconds() + 1));
    this.manageBatchJobService.getJobDetailsById(this.id).subscribe((data) => {
      this.jobTitle = data.title;
      this.titleFetched = true;
      const createdAtFormatted = moment(data.createdAt).format("YYYY-MM-DDTHH:mm:ss");

      if (!this.route.snapshot.paramMap.get("executionTime")) {
        this.datePickerForm = this.fb.group({
          startDate: [createdAtFormatted],
          endDate: [this.maxDate],
        });
      } else {
        this.datePickerForm = this.fb.group({
          startDate: [this.executionTime],
          endDate: [this.executionTime],
        });
      }
    });

    this.manageBatchJobService
      .getLogsByTimeRange(this.id, this.executionTime, this.executionTime)
      .subscribe(
        (data) => {
          data.map(
            (x) =>
              (x.formattedExecutionTime = this.manageBatchJobService.convertDateToLocal(
                x.executionTime
              ))
          );
          this.dataSource = data;
          this.loaded = true;
        },
        (error: Error) => {
          this.error = error.message;
        }
      );
  }

  async searchByDate(event) {
    let startDateUTC: any; //eslint-disable-line @typescript-eslint/no-explicit-any
    let endDateUTC: any; //eslint-disable-line @typescript-eslint/no-explicit-any

    startDateUTC = event.startDate._d
      ? new Date(event.startDate._d).toUTCString()
      : new Date(event.startDate).toUTCString();
    startDateUTC = moment.utc(startDateUTC).format("YYYY-MM-DDTHH:mm:ss");
    endDateUTC = event.endDate._d
      ? new Date(event.endDate._d).toUTCString()
      : new Date(event.endDate).toUTCString();
    endDateUTC = moment.utc(endDateUTC).format("YYYY-MM-DDTHH:mm:ss");

    this.manageBatchJobService.getLogsByTimeRange(this.id, startDateUTC, endDateUTC).subscribe(
      (data) => {
        data.map(
          (x: IListExecutionHistoryView) =>
            (x.formattedExecutionTime = this.manageBatchJobService.convertDateToLocal(
              x.executionTime
            ))
        );
        this.dataSource = data;
      },
      (error: Error) => {
        this.error = error.message;
      }
    );
  }

  areFilterDatesValid(startDateUTC, endDateUTC) {
    startDateUTC = startDateUTC
      ? new Date(startDateUTC).toUTCString()
      : moment(this.datePickerForm.controls.startDate.value);
    endDateUTC = endDateUTC
      ? new Date(endDateUTC).toUTCString()
      : moment(this.datePickerForm.controls.endDate.value);
    this.filterValid = moment.utc(startDateUTC) <= moment.utc(endDateUTC);
  }

  navigateToViewLogsPage(executionTime, logFileName) {
    this.router.navigate([`../viewlogdetails/${this.id}/${executionTime}/${logFileName}`], {
      relativeTo: this.route,
    });
  }
}

interface IListExecutionHistoryView extends IListExecutionHistory {
  formattedExecutionTime: string;
}
