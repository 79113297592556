import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ConfirmationPopupComponent } from "../confirmation-popup.component";
import { ConfimationPopupData } from "../models/confirmation-popup-data";

@Injectable({
  providedIn: "root",
})
export class ConfirmationPopupService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(data: ConfimationPopupData): Observable<boolean> {
    return this.dialog
      .open(ConfirmationPopupComponent, {
        data,
        width: "400px",
        disableClose: true,
      })
      .afterClosed();
  }
}
