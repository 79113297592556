import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { HomeComponent } from "./home.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [HomeComponent],
  imports: [CommonModule, ConnectCoreModule, MatIconModule],
})
export class StreamingModule {}
