<con-page-layout>
  <con-page-header header="{{ 'filetransfer.page_title' | translate }}">
    <ul class="breadcrumb" *ngIf="currentFolderPath.length > 0">
      <li>{{ "workspaces.current_folder_text" | translate }}</li>
      <li *ngFor="let path of currentFolderPath">
        <span>{{ path }}</span>
      </li>
    </ul>
  </con-page-header>

  <con-page-layout-content>
    <mat-card>
      <mat-card-content>
        <ng-container *ngIf="error; then errorState; else okState"></ng-container>

        <ng-template #okState>
          <div class="info-container" *ngIf="featureEnabled">
            <div class="information">
              <h4 class="label">{{ "filetransfer.cloudstorage.title" | translate }}</h4>
              {{ "filetransfer.cloudstorage.text" | translate }}
            </div>
            <uilab-button (click)="downloadCloudStorageClient()">{{
              "filetransfer.cloudstorage.download_button" | translate
            }}</uilab-button>
          </div>
        </ng-template>

        <ng-template #errorState>
          <con-page-layout-content>
            <div class="content-container">
              <div class="inner-container">
                <div class="warning-container greeting-container">
                  <h4 class="error-page-title">
                    <mat-icon class="vertical-align-middle padding-bottom-3">warning</mat-icon>
                    {{ "filetransfer.cloudstorage.warning_title" | translate }}
                  </h4>
                  <p>{{ error }}</p>
                  <p [innerHtml]="'filetransfer.cloudstorage.warning_sub_title_html' | translate"></p>
                </div>
              </div>
            </div>
          </con-page-layout-content>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </con-page-layout-content>
</con-page-layout>
