import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersTableComponent } from '../users-table/users-table.component';
import { AuthenticatedUser } from '@aveva/connect-web-core';
import { ActivatedRoute } from '@angular/router';
import { WorkspaceService, WorkspaceUser } from '../workspace.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  public error: string;
  public currentFolderPath: string[] = [];
  private users: WorkspaceUser[] = [];
  public currentUser: AuthenticatedUser;
  public usersTableData: WorkspaceUser[];
  public loaded = false;

  @ViewChild(UsersTableComponent, { static: true })
  usersTable: UsersTableComponent;

  constructor(
    private route: ActivatedRoute,
    private workspaceService: WorkspaceService,
    private confirmationDialog: ConfirmationPopupService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.route.data.subscribe(
      (data: { user: AuthenticatedUser; arrayOfPaths: string[] }) => {
        this.currentUser = data.user;
        this.currentFolderPath = data.arrayOfPaths;
      }
    );
    await this.getUserWorkspaces();
    this.setTableData();
  }

  setTableData(filterValue?: string) {
    let result = [];
    if (filterValue !== undefined) {
      filterValue = filterValue.toLowerCase();
      result =
        this.users !== undefined || this.users?.length > 0
          ? this.users.filter(
            (x) =>
              x.username.toLowerCase().includes(filterValue) ||
              x.state.toLowerCase().includes(filterValue) ||
              x.computeType.toLowerCase().includes(filterValue)
          )
          : [];
    } else {
      result = this.users;
    }
    this.usersTableData = result
      .sort((obj1, obj2) => this.sortUsers(obj1, obj2))
      .slice(0);
  }

  async getUserWorkspaces() {
    try {
      this.users = [
        ...(await this.workspaceService.getWorkspaces().toPromise()),
      ];
      this.loaded = true;
    } catch (e) {
      this.error = e.message;
    }
  }

  async removeWorkspace(user: WorkspaceUser): Promise<void> {
    user.disabled = true;
    const username = user.username;

    this.confirmationDialog
      .confirmDialog({
        title: 'delete_user',
        message: this.translate.instant('workspaces.admin.delete_desktop_description', { username }),
        confirmCaption: 'confirmation_popup.delete',
        cancelCaption: 'confirmation_popup.cancel',
      })
      .subscribe(async (confirmed) => {
        try {
          if (confirmed) {
            await this.workspaceService
              .removeWorkspace(user.workspaceId)
              .toPromise();
            await this.getUserWorkspaces();
            this.setTableData();
          } else {
            user.disabled = false;
          }
        } catch (e) {
          this.error = e.message;
        }
      });
  }

  private sortUsers(obj1: WorkspaceUser, obj2: WorkspaceUser) {
    if (obj1.username > obj2.username) {
      return 1;
    }
    if (obj1.username < obj2.username) {
      return -1;
    }
    return 0;
  }
}
