import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { AuthRoleCheckService } from "../auth-role-check-service";
import { FileTransferService } from "./filetransfer.service";
import { AuthenticationService } from "@aveva/connect-web-core";

@Injectable()
export class FileTransferFeatureAccessGuard implements CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private authRoleCheckService: AuthRoleCheckService,
    private fileTransferService: FileTransferService
  ) { }

  async canActivateChild(
    _childRoute: ActivatedRouteSnapshot, //eslint-disable-line  @typescript-eslint/no-unused-vars
    _state: RouterStateSnapshot //eslint-disable-line  @typescript-eslint/no-unused-vars
  ): Promise<boolean> {
    const user = await this.authService.getUser();
    // same rules should be in root.component.ts
    return (
      (
        (await this.authRoleCheckService.isAdminSuperUser(user)) ||
        (await this.authRoleCheckService.isAccountAdmin(user))
      ) &&
      (await this.fileTransferService.featureEnabled().toPromise())
    );
  }
}
